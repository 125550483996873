@import "src/app/global/variables.scss";
.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-left: 125px;
    padding-right: 125px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    .image {
        width: auto;
        object-fit: contain;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: -1;
    }
    @media screen and (min-width: 1439px) {
        padding-left: calc((100vw - 1340px) / 2);
        padding-right: calc((100vw - 1340px) / 2);
    }
    @media screen and (max-width: 1024px) {
        background-size: auto calc(100vh + 150px);
    }

    @media screen and (max-width: 767px) {
        background-size: auto 90vh;
        top: 0;
        background-position: top center;
    }
}

.GoogleButton {
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    @media screen and (max-width: 1024px) {
        height: 80px;
        width: auto;
    }
    @media screen and (max-width: 767px) {
        height: 52px;
        width: auto;
    }
}
.AppStoreButton {
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    @media screen and (max-width: 1024px) {
        height: 80px;
        width: auto;
    }
    @media screen and (max-width: 767px) {
        height: 52px;
        width: auto;
    }
}
.contentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
    width: 100%;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        margin-top: 100px;
    }

    @media screen and (max-width: 767px) {
        margin-top: 0px;
    }
}
.logo {
    position: fixed;
    z-index: 3;
    left: calc(50% - 355px / 2);
    top: 56px;
    width: 355px;
    height: auto;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    @media screen and (max-width: 1024px) {
        position: relative;
        flex-direction: row;
        justify-content: auto;
        top: -110px;
    }

    @media screen and (max-width: 767px) {
        position: relative;
        flex-direction: row;
        justify-content: auto;
        top: -80px;
    }
}
.licenseCheckForm {
    position: relative;
    z-index: 5;
    border-radius: 20px;
    border: 1px solid #fff;
    background: #f6f6f6;
    backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 32px;
    border-radius: 20px;
    max-height: 232px;
    min-height: 232px;
    box-sizing: border-box;

    h3 {
        color: #000;
        font-family: "Berthold Standard";
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px; /* 150% */
        max-width: 224px;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 42px 24px;
        place-self: center;
        width: 100%;
        max-width: 531px;
        align-items: center;
        .headContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        h3 {
            max-width: 9999px;
        }
    }

    @media screen and (max-width: 1024px) {
        position: absolute;
        bottom: 50px;
        gap: 20px;
        width: 100vw;
        h3 {
            font-size: 24px;
            max-width: none;
        }
    }

    @media screen and (max-width: 767px) {
        position: absolute;
        bottom: -30px;
        gap: 20px;
        width: 100vw;
        h3 {
            font-size: 24px;
            max-width: none;
        }
    }
}

.policies {
    display: none;
    @media screen and (max-width: 767px) {
        position: absolute;
        display: flex;
        gap: 24px;
        top: -40px;
        right: auto;
        a {
            color: #fff;
            font-family: "Berthold Standard";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px; /* 180% */
            text-decoration-line: underline;
        }
    }
}

.policiesDesk {
    position: absolute;
    display: flex;
    gap: 24px;
    top: 32px;
    right: 150px;
    a {
        color: #fff;
        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 180% */
        text-decoration-line: underline;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.licenseField {
    border-bottom: 1px solid gray !important;

    &.focus {
        transition-duration: 500ms;
        border-bottom: 1px solid $primary !important;
    }
    input {
        padding: 12px;
        min-width: 202px;

        color: #000;
        font-family: "Berthold Standard";
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px; /* 180% */
    }

    @media screen and (max-width: 767px) {
        input {
            padding: 12px;
            min-width: 150px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        input {
            padding: 16px;
            min-width: 250px;
        }
    }
}

.checkButton {
    display: flex;
    width: 56px;
    padding: 16px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #000;
    border: none;

    &:hover {
        opacity: 0.8;
    }
}

.DiagArrow {
    path {
        width: 24px;
        height: 24px;
    }
}

.bottomContainer {
    display: flex;
    gap: 24px;
}

.confirm {
    max-width: 250px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    width: 330px;
    padding: 42px 39px 38px 39px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #fff;
    background: #fff;
    backdrop-filter: blur(6px);

    p {
        color: #000;
        text-align: center;
        font-family: "Berthold Standard";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        align-self: stretch;
    }

    a {
        border-radius: 12px;
        color: white;
        background-color: #15CF74;
        padding: 12px 24px;

        &:hover {
            opacity: 0.8;
        }
    }
}
