@import "src/app/global/variables.scss";
* {
    font-family: "Archivo", "Poppins", sans-serif,
        -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue";
    margin: 0;
}

html,
body {
    overflow-x: hidden;
}
a {
    text-decoration: none;
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

img {
    object-fit: contain;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        "Courier New", monospace;
}


@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"),
        url("../shared/fonts/Berthold/BertholdStandard.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"),
        url("../shared/fonts/Berthold/BertholdStandardLight.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"),
        url("../shared/fonts/Berthold/BertholdStandardMedium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Berthold Standard";
    src: local("BertholdStandard"),
        url("../shared/fonts/Berthold/BertholdStandardBold.ttf");
    font-weight: 700;
}
